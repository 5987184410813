<template>
  <div>
    <BFormGroup
      label="Strings"
      description="Number of strings in the site"
      :invalid-feedback="validation.strings.message"
      :state="validation.strings.valid ? null : false"
      :disabled="loading">

      <BFormInput
        type="number"
        min="1"
        :value="information.strings"
        @input="(val) => update('strings', transformNumber(val))"
        :state="validation.strings.valid ? null : false" />
    </BFormGroup>

    <BFormGroup
      label="Modules Per String"
      description="Number of modules per string"
      :invalid-feedback="validation.modulesPerString.message"
      :state="validation.modulesPerString.valid ? null : false"
      :disabled="loading">

      <BFormInput
        type="number"
        min="1"
        :value="information.modulesPerString"
        @input="(val) => update('modulesPerString', transformNumber(val))"
        :state="validation.modulesPerString.valid ? null : false" />
    </BFormGroup>

    <BFormGroup
      label="Inverter Efficiency"
      :invalid-feedback="validation.inverterEfficiency.message"
      :state="validation.inverterEfficiency.valid ? null : false"
      :disabled="loading">

      <BInputGroup
        append="%">
        <BFormInput
          type="number"
          min="0"
          :value="information.inverterEfficiency"
          @input="(val) => update('inverterEfficiency', transformNumber(val))"
          :state="validation.inverterEfficiency.valid ? null : false" />
      </BInputGroup>
    </BFormGroup>

    <BFormGroup
      label="Inverter Nameplate Power"
      :invalid-feedback="validation.inverterNameplatePower.message"
      :state="validation.inverterNameplatePower.valid ? null : false"
      :disabled="loading">

      <BInputGroup
        append="MW">
        <BFormInput
          type="number"
          min="0"
          :value="information.inverterNameplatePower"
          @input="(val) => update('inverterNameplatePower', transformNumber(val))"
          :state="validation.inverterNameplatePower.valid ? null : false" />
      </BInputGroup>
    </BFormGroup>

    <BFormGroup
      label="PPA"
      :invalid-feedback="validation.ppa.message"
      :state="validation.ppa.valid ? null : false"
      :disabled="loading">

      <BInputGroup
        append="$/MWh">
        <BFormInput
          type="number"
          min="0"
          step="1"
          :value="information.ppa"
          @input="(val) => update('ppa', transformNumber(val))"
          :state="validation.ppa.valid ? null : false" />
      </BInputGroup>
    </BFormGroup>

    <div class="d-flex align-items-center justify-content-end">
      <MsiSpinner
        :size="30"
        v-if="loading" />

      <BButton
        variant="primary"
        class="ml-2"
        size="sm"
        @click="handleSave"
        :disabled="loading || !siteInformationChanged || !valid">
        Save
      </BButton>
    </div>

    <BModal
      title="Update Site Settings?"
      hide-footer
      v-model="updateSiteWarning">

      <div>
        You are about to update the site settings and will lose all your plotted graphs.
        Are you sure you want to update the settings?
      </div>

      <div class="d-flex justify-content-end pt-2">
        <BButton
          variant="primary"
          size="sm"
          class="mr-2"
          @click="handleUpdateSite">
          Update Settings
        </BButton>

        <BButton
          variant="secondary"
          size="sm"
          @click="cancelUpdateSite">
          Cancel
        </BButton>
      </div>
    </BModal>
  </div>
</template>

<script>
import { BFormGroup, BFormInput, BButton, BModal, BInputGroup } from 'bootstrap-vue';
import MsiSpinner from '@/components/MsiSpinner.vue';
import { validateNumber, transformers } from '@/components/SiteSettings/EditModuleDesignForm/formUtils';

export default {
  name: 'SiteInformationForm',
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BModal,
    BInputGroup,
    MsiSpinner
  },
  mixins: [transformers],
  props: {
    site: Object,
    warn: Boolean
  },
  data() {
    return {
      information: {},
      loading: false,
      updateSiteWarning: false
    };
  },
  computed: {
    validation() {
      return {
        strings: validateNumber(this.information.strings, 'Strings', { min: 1, integer: true }),
        modulesPerString: validateNumber(this.information.modulesPerString, 'Modules Per String', { min: 1, integer: true }),
        inverterEfficiency: validateNumber(this.information.inverterEfficiency, 'Inverter Efficiency', { min: 0 }),
        inverterNameplatePower: validateNumber(this.information.inverterNameplatePower, 'Inverter Nameplate Power', { min: 0 }),
        ppa: validateNumber(this.information.ppa, 'PPA', { min: 0 })
      };
    },
    valid() {
      return !Object.values(this.validation).some(v => !v.valid);
    },
    siteInformationChanged() {
      if (!this.site) return false;
      const originalInformation = this.site.information || {};
      const newInformation = this.information || {};
      if (Object.keys(originalInformation).length !== Object.keys(newInformation).length) return true;
      return Object.keys(originalInformation).some(key => originalInformation[key] !== newInformation[key]);
    }
  },
  methods: {
    update(key, value) {
      this.information[key] = value;
    },
    handleSave() {
      if (this.warn) {
        this.updateSiteWarning = true;
      } else {
        this.handleUpdateSite();
      }
    },
    async handleUpdateSite() {
      try {
        this.loading = true;
        const body = { information: this.information };
        await this.$daqApi.put(`/sites/${this.site.id}`, { body });
        await this.$store.set('sites/sites');
        this.$toastSuccess('Successful', 'The site\'s information has been updated successfully.');
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        this.updateSiteWarning = false;
        this.loading = false;
      }
    },
    cancelUpdateSite() {
      this.updateSiteWarning = false;
    },
    handleReset() {
      this.information = JSON.parse(JSON.stringify(this.site.information));
    }
  },
  watch: {
    site: {
      immediate: true,
      handler() {
        if (this.site && this.site.information) this.handleReset();
      }
    },
    siteInformationChanged() {
      this.$emit('changed', this.siteInformationChanged);
    }
  }
};
</script>
