<template>
  <div>
    <BFormGroup
      label=""
      :description="`
        Allows you to set site-level default metrics.
        Groups and modules inherit these default metrics unless they override them.
      `">

      <DefaultMetrics
        v-model="defaultMetrics"
        :metrics="metrics" />
    </BFormGroup>

    <div class="d-flex align-items-center justify-content-end">
      <MsiSpinner
        :size="30"
        v-if="loading" />

      <BButton
        variant="primary"
        class="ml-2"
        size="sm"
        @click="handleSave"
        :disabled="loading || !defaultMetricsChanged">
        Save
      </BButton>
    </div>

    <BModal
      title="Update Site Settings?"
      hide-footer
      v-model="updateSiteWarning">

      <div>
        You are about to update the site settings and will lose all your plotted graphs.
        Are you sure you want to update the settings?
      </div>

      <div class="d-flex justify-content-end pt-2">
        <BButton
          variant="primary"
          size="sm"
          class="mr-2"
          @click="handleUpdateSite">
          Update Settings
        </BButton>

        <BButton
          variant="secondary"
          size="sm"
          @click="cancelUpdateSite">
          Cancel
        </BButton>
      </div>
    </BModal>
  </div>
</template>

<script>
import { BFormGroup, BButton, BModal } from 'bootstrap-vue';
import { get } from 'vuex-pathify';
import MsiSpinner from '@/components/MsiSpinner.vue';

import DefaultMetrics from '@/components/SiteSettings/DefaultMetrics.vue';

export default {
  name: 'SiteDefaultMetricsForm',
  components: {
    BFormGroup,
    BButton,
    BModal,
    MsiSpinner,
    DefaultMetrics
  },
  props: {
    site: Object,
    warn: Boolean
  },
  data() {
    return {
      defaultMetrics: {},
      loading: false,
      updateSiteWarning: false
    };
  },
  computed: {
    getSiteMetrics: get('metrics/getSiteMetrics'),
    metrics() {
      if (!this.site) return [];
      return this.getSiteMetrics(this.site.id);
    },
    defaultMetricsChanged() {
      if (!this.site) return false;
      const originalDefaultMetrics = this.site.ownDefaultMetrics || {};
      const newDefaultMetrics = this.defaultMetrics || {};
      if (Object.keys(originalDefaultMetrics).length !== Object.keys(newDefaultMetrics).length) return true;
      return Object.keys(originalDefaultMetrics).some(key => originalDefaultMetrics[key] !== newDefaultMetrics[key]);
    }
  },
  methods: {
    handleSave() {
      if (this.warn) {
        this.updateSiteWarning = true;
      } else {
        this.handleUpdateSite();
      }
    },
    async handleUpdateSite() {
      try {
        this.loading = true;
        const body = { defaultMetrics: this.defaultMetrics };
        await this.$daqApi.put(`/sites/${this.site.id}`, { body });
        await this.$store.set('sites/sites');
        this.$toastSuccess('Successful', 'The site\'s default metrics have been updated successfully.');
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        this.updateSiteWarning = false;
        this.loading = false;
      }
    },
    cancelUpdateSite() {
      this.updateSiteWarning = false;
    },
    handleReset() {
      this.defaultMetrics = this.site.ownDefaultMetrics;
    }
  },
  watch: {
    site: {
      immediate: true,
      handler() {
        if (this.site && this.site.ownDefaultMetrics) this.handleReset();
      }
    },
    defaultMetricsChanged() {
      this.$emit('changed', this.defaultMetricsChanged);
    }
  }
};
</script>
