<template>
  <BContainer fluid class="site-settings-container m-0 px-2 px-md-3 pt-2 pt-md-3 d-flex flex-column">
    <div class="flex-grow-1 d-flex flex-column pb-2 pb-md-3">
      <BCard no-body class="m-0 p-3 flex-grow-1 d-flex flex-column">
        <BTabs
          class="flex-grow-1 d-flex flex-column">

          <BTab
            title="Site Information"
            active>

            <SiteInformationForm
              class="site-settings-default-information"
              :site="selectedSite"
              :warn="selectedSiteActiveAnalysis"
              @changed="handleInformationFormChanged" />
          </BTab>

          <BTab
            title="Default Metrics">

            <SiteDefaultMetricsForm
              class="site-settings-default-metrics"
              :site="selectedSite"
              :warn="selectedSiteActiveAnalysis"
              @changed="handleDefaultMetricsFormChanged" />
          </BTab>
        </BTabs>
      </BCard>
    </div>

    <BModal
      title="Discard Changes to Site Settings?"
      hide-footer
      v-model="warning">

      <div>
        You have not saved your site settings and will lose your changes if you continue. Are you sure you want to continue?
      </div>

      <div class="d-flex justify-content-end pt-2">
        <BButton
          variant="primary"
          size="sm"
          class="mr-2"
          @click="handleContinue">
          Continue
        </BButton>

        <BButton
          variant="secondary"
          size="sm"
          @click="handleCancel">
          Cancel
        </BButton>
      </div>
    </BModal>
  </BContainer>
  </template>

<script>
import { BContainer, BCard, BTabs, BTab, BModal, BButton } from 'bootstrap-vue';
import { get } from 'vuex-pathify';

import SiteDefaultMetricsForm from '@/components/SiteSettings/SiteDefaultMetricsForm.vue';
import SiteInformationForm from '@/components/SiteSettings/SiteInformationForm.vue';

export default {
  name: 'SiteSettings',
  components: {
    BContainer,
    BCard,
    BTabs,
    BTab,
    BModal,
    BButton,
    SiteInformationForm,
    SiteDefaultMetricsForm
  },
  data() {
    return {
      warning: false,
      informationFormChanged: false,
      defaultMetricsFormChanged: false
    };
  },
  computed: {
    selectedSite: get('sites/selectedSite'),
    selectedSiteActiveAnalysis: get('sites/selectedSiteActiveAnalysis'),
    siteSettingsChanged() {
      return this.informationFormChanged || this.defaultMetricsFormChanged;
    }
  },
  methods: {
    handleInformationFormChanged(changed) {
      this.informationFormChanged = changed;
    },
    handleDefaultMetricsFormChanged(changed) {
      this.defaultMetricsFormChanged = changed;
    },
    handleContinue() {
      if (this.$options.warningRoute) this.$router.push(this.$options.warningRoute);
    },
    handleCancel() {
      this.warning = false;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.siteSettingsChanged && !this.warning) {
      this.warning = true;
      this.$options.warningRoute = to;
      next(false);
    } else {
      next();
    }
  }
};
</script>

<style scoped lang="scss">
.site-settings-container {
  height: calc(100vh - 105px)
}

.site-settings-container::v-deep .tab-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.site-settings-container::v-deep .tab-pane.active {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.site-settings-default-metrics {
  max-width: 1000px;
}

.site-settings-default-information {
  max-width: 500px;
}
</style>
